import { useEffect, useState } from 'react';

import { faFilePdf, faSprayCan, faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ProfilePhoto, SidebarNav } from '../../components';
import { useCustomer, useTokenAuth } from '../../hooks';

import capitalize from '../../utils/capitalize';
import postJSON from '../../utils/postJSON';
import toDollars from '../../utils/toDollars';

import {
  ADDRESS,
  ARE_YOU_SURE,
  CONTACT,
  CREATE_INVOICE,
  CREATE_LIST,
  DEFAULT_RATE,
  EDIT_PROFILE,
  ENTER_VALID_ADDRESS,
  ENTER_VALID_EMAIL,
  ENTER_VALID_PHONE,
  ENTER_WHOLE_NUMBER,
  INVOICES,
  IN_HOME_RATE_LABEL,
  LIST_NAME,
  LISTS,
  NOTE,
  NO_INVOICES,
  RATE,
  SAVE,
  TASKS,
  UPDATE_URL
} from '../../constants';

import '../../dashboard.css';

const { REACT_APP_API_URL } = process.env;

const NO_SPACING = {
  margin: 0,
  padding: 0
};

const CLEAR_LIST_STYLE = {
  ...NO_SPACING,

  listStyle: 'none'
};

export default ({ slug }) => {
  const { email, token } = useTokenAuth();
  const { customer, setCustomer } = useCustomer({ slug });

  const [customerName, setCustomerName] = useState(customer?.ownerName);
  const [customerNotes, setCustomerNotes] = useState(customer?.notes);
  const [customerEmail, setCustomerEmail] = useState(customer?.email);
  const [customerPhone, setCustomerPhone] = useState(customer?.phone);
  const [customerAddress, setCustomerAddress] = useState(customer?.address);
  const [customerRate, setCustomerRate] = useState(customer?.rate || DEFAULT_RATE);
  const [customerLists, setCustomerLists] = useState(customer?.lists || []);
  const [isCreatingList, setisCreatingList] = useState(false);
  const [listName, setListName] = useState('');
  const [taskName, setTaskName] = useState('');
  const [tasks, setTasks] = useState([]);
  const [isSidebarNavOpen, setIsSidebarNavOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setCustomerName(customer?.ownerName);
    setCustomerNotes(customer?.notes);
    setCustomerEmail(customer?.email);
    setCustomerPhone(customer?.phone);
    setCustomerAddress(customer?.address);
    setCustomerRate(customer?.rate);
    setCustomerLists(customer?.lists || []);
  }, [customer]);

  const onClickSave = async () => {
    const result = await postJSON(UPDATE_URL, {
      customerId: customer.id,
      ownerName: customerName,
      phone: customerPhone,
      address: customerAddress,
      notes: customerNotes,
      rate: customerRate,
      lists: customerLists,
      token,
      username: email
    });

    setIsEditing(false);

    if (result?.success) {
      setCustomer({
        ...customer,

        notes: customerNotes,
        email: customerEmail,
        phone: customerPhone,
        address: customerAddress,
        rate: customerRate,
        lists: customerLists
      });
    }
  };

  const onChangeNotes = ({ target: { value } }) => setCustomerNotes(value);

  const onChangeEmail = ({ target: { value } }) => setCustomerEmail(value);

  const onChangeAddress = ({ target: { value } }) => setCustomerAddress(value);

  const onChangeRate = ({ target: { value } }) => setCustomerRate(value);

  const onChangePhone = ({ target: { value } }) => setCustomerPhone(value);

  const onChangeListName = ({ target: { value } }) => setListName(value);

  const onChangeTaskName = ({ target: { value } }) => setTaskName(value);

  const onAddTask = task => {
    setTasks([
      ...tasks,

      task
    ]);

    setTaskName('');
  };

  const onClickInvoiceLink = invoiceId => () => {
    window.location.href = `/invoice/${customer.slug}/${invoiceId}`;
  };

  const onClickCreateInvoice = () => {
    window.location.href = `/invoice/${customer.slug}`;
  };

  const onClickDeleteInvoice = id => async () => {
    if (!window.confirm(ARE_YOU_SURE)) return;

    const response = await fetch(`${REACT_APP_API_URL}/delete-invoice`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id,
        customerId: customer.id,
        token,
        username: email
      })
    });

    if (response?.ok) {
      const result = await response.json();

      if (result?.success) {
        window.location.reload();
      }
    }
  };

  const onClickCreateList = () => setisCreatingList(true);

  const onClickDone = () => {
    setCustomerLists([
      ...customerLists,

      {
        name: listName,
        tasks
      }
    ]);

    setisCreatingList(false);
    setListName('');
  };

  const onClickMenu = () => {
    setIsSidebarNavOpen(!isSidebarNavOpen);
  };

  const onClickEdit = () => setIsEditing(!isEditing);

  if (!customer?.id) {
    return <div />;
  }

  const {
    id,
    ownerName,
    catName,
    address,
    phone,
    email: emailAddress,
    notes,
    invoices = [],
    rate = DEFAULT_RATE
  } = customer;

  const customerEmailAddress = emailAddress || id;

  return (
    <>
      <section className="sidebar-section">
        <SidebarNav isOpen={isSidebarNavOpen} setIsOpen={setIsSidebarNavOpen} />
        <div className="customers-wrapper">
          <h2>
            <i onClick={onClickMenu}>=</i>@{slug}
          </h2>
          <header className="profile-header">
            <ProfilePhoto />
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ fontSize: '1.2em' }}>{catName}</h3>
              <h3>{ownerName}</h3>
              {!isEditing && (
                <div className="panel">
                  <section>
                    {customerEmailAddress && <p>{customerEmailAddress}</p>}
                    {!customerEmailAddress && (
                      <p className="panel">⚠️ No email address</p>
                    )}
                    {phone && <p className="phone">{phone}</p>}
                    {!phone && <p className="panel">⚠️ No phone number</p>}
                    <p style={{ marginTop: '2.5rem' }}>{address}</p>
                  </section>
                  <section>{notes || '-'}</section>
                  <section>
                    <ul style={{
                      ...CLEAR_LIST_STYLE,

                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gridAutoRows: '1fr',
                      gap: '1rem'
                    }}>
                      {customerLists.map(list => (
                        <li
                          key={list.name}
                          style={CLEAR_LIST_STYLE}
                        >
                          <span
                            className="panel"
                            style={{
                              display: 'block',
                              textAlign: 'left',
                              background: 'white',
                              borderBottom: '2px solid #9418ff50',
                              margin: 0
                            }}
                          >
                            <h3>{list.name}</h3>
                            <h6 style={{
                              fontSize: '.75em',
                              margin: 0,
                              textTransform: 'uppercase',
                              display: 'flex',
                              gap: '.5rem',
                              alignItems: 'center',
                              justifyContent: 'start'
                            }}>
                              <FontAwesomeIcon icon={faTasks} color="#9418ff" />
                              Tasks
                            </h6>
                            {list.tasks.map(({ name }) => (
                              <p style={{ color: 'black' }}>
                                {name}
                              </p>
                            ))}
                            <h6 style={{
                              fontSize: '.75em',
                              margin: 0,
                              textTransform: 'uppercase',
                              display: 'flex',
                              gap: '.5rem',
                              alignItems: 'center',
                              justifyContent: 'start'
                            }}>
                              <FontAwesomeIcon icon={faSprayCan} color="#9418ff" />
                              Supplies
                            </h6>
                            {list.tasks.map(({ supplies = [] }) => (
                              <p>{supplies.join(', ')}</p>
                            ))}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </section>
                  <section className="disclaimer">
                    {IN_HOME_RATE_LABEL} {toDollars(rate)}
                  </section>
                </div>
              )}
            </div>
          </header>
          {isEditing && (
            <>
              <p className="dashboard-heading">{CONTACT}</p>
              <input
                type="email"
                placeholder={ENTER_VALID_EMAIL}
                defaultValue={id}
                onChange={onChangeEmail}
                value={customerEmail}
                disabled
              />
              <input
                type="tel"
                placeholder={ENTER_VALID_PHONE}
                defaultValue={phone}
                onChange={onChangePhone}
                value={customerPhone}
              />
              <p className="dashboard-heading">{capitalize(ADDRESS)}</p>
              <input
                type="address"
                placeholder={ENTER_VALID_ADDRESS}
                defaultValue={address}
                onChange={onChangeAddress}
                value={customerAddress}
              />
              <p className="dashboard-heading">{LISTS}</p>
              {customerLists.map(list => (
                (list?.name && <div
                  key={list.name}
                  style={{
                    color: '#9418ff',
                    margin: '.5rem 0',
                    padding: '.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '.5rem'
                  }}
                >
                  <FontAwesomeIcon icon={faTasks} color="#9418ff" />
                  {list.name}
                </div>)
              ))}
              {isCreatingList && <aside
                className="panel"
                style={{
                  margin: '1rem 0',
                  width: '20rem'
                }}
              >
                <h3>{CREATE_LIST}</h3>
                <p className="dashboard-heading">{LIST_NAME}</p>
                <input
                  defaultValue={listName}
                  onChange={onChangeListName}
                  value={listName}
                  placeholder="Morning visits"
                  style={{
                    padding: '.5rem',
                    width: '100%'
                  }}
                />
                <p className="dashboard-heading">{TASKS}</p>
                {tasks.map(task => (
                  <p key={task.name} style={{ color: 'black' }}>
                    {task.name}
                  </p>
                ))}
                <input
                  defaultValue={taskName}
                  onChange={onChangeTaskName}
                  value={taskName}
                  placeholder="Clean the litter box"
                  style={{
                    padding: '.5rem',
                    width: '100%'
                  }}
                />
                {taskName && <p className="dashboard-heading">
                  <button
                    onClick={() => onAddTask({
                      name: taskName,
                      supplies: []
                    })}
                    style={{
                      margin: '1rem auto',
                      background: 'black'
                    }}
                  >
                    Add Task
                  </button>
                </p>}
                <button
                  onClick={onClickDone}
                  style={{
                    margin: '1rem 0',
                    width: '100%'
                  }}
                >
                  Done
                </button>
              </aside>}
              {!isCreatingList && <p className="dashboard-heading">
                <button onClick={onClickCreateList} style={{ background: 'black' }}>
                  {CREATE_LIST}
                </button>
              </p>}
              <p className="dashboard-heading">{NOTE}</p>
              <textarea defaultValue={customerNotes} onChange={onChangeNotes} />
              <p className="dashboard-heading">{RATE}</p>
              <input
                type="number"
                placeholder={ENTER_WHOLE_NUMBER}
                defaultValue={rate}
                onChange={onChangeRate}
                value={customerRate}
              />
              <button onClick={onClickSave}>{SAVE}</button>
            </>
          )}
          {!isEditing && <>
            <button onClick={onClickEdit}>{EDIT_PROFILE}</button>
            <div className="panel file-list">
              <p className="dashboard-heading" style={NO_SPACING}>
                {INVOICES}
              </p>
              <ul className="profile-invoices">
                {invoices.map(({ id, createdAt }) => (
                  <li key={id}>
                    <FontAwesomeIcon icon={faFilePdf} color="#9418ff" />
                    <button
                      onClick={onClickInvoiceLink(id)}
                      style={{
                        ...NO_SPACING,

                        background: 'none',
                        color: '#9418ff',
                        textDecoration: 'underline'
                      }}
                    >
                      {createdAt}
                    </button>
                    <button
                      onClick={onClickDeleteInvoice(id)}
                      className="delete-button"
                    >
                      ×
                    </button>
                  </li>
                ))}
              </ul>
              {invoices.length === 0 ? (
                <p style={{ opacity: 0.5 }}>{NO_INVOICES}</p>
              ) : (
                ''
              )}
              <button onClick={onClickCreateInvoice}>{CREATE_INVOICE}</button>
            </div>
          </>}
        </div>
      </section>
    </>
  );
};
