export default () => {
  return {
    sitters: [
      {
        id: '3d1fb355-9a6e-474c-9db7-338116c270b7',
        name: 'Chelsea'
      },
      {
        id: '43288b71-af9f-4915-897a-1c8264b1c7c0',
        name: 'Benny'
      }
    ]
  }
};
