import React, { useEffect, useState } from 'react';

import { CALENDAR, CLOSE, CUSTOMERS, EXIT, INVOICES } from '../../constants';

export default ({ isOpen = false, setIsOpen }) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onClickClose = () => setIsOpen(false);

  const [page] = window.location.pathname
    .trim()
    .toLowerCase()
    .split('/')
    .filter(Boolean);

  let currentPage = '';

  const calendar = CALENDAR.toLowerCase();
  const customers = CUSTOMERS.toLowerCase();
  const invoices = INVOICES.toLowerCase();

  switch (page) {
    case 'customers':
    case 'profile':
      currentPage = customers;

      break;

    case 'calendar':
      currentPage = calendar;

      break;

    case 'invoices':
    case 'invoice':
      currentPage = invoices;

      break;
  }

  return (
    <aside className={`sidebar${open ? '-open' : ''} left`}>
      <ul className="sidebar-nav" role="navigation">
        <li style={{ opacity: 0.75, fontSize: '0.75em' }}>
          <a href="/">&lt; {EXIT}</a>
        </li>
        <li className={currentPage === customers ? 'selected' : ''}>
          <span>•</span>
          <a href="/customers">{CUSTOMERS}</a>
        </li>
        <li className={currentPage === calendar ? 'selected' : ''}>
          <span>•</span>
          <a href="/calendar">{CALENDAR}</a>
        </li>
        <li className={currentPage === invoices ? 'selected' : ''}>
          <span>•</span>
          <a href="/invoices">{INVOICES}</a>
        </li>
      </ul>
      <button className="sidebar-close-button" onClick={onClickClose}>
        {CLOSE}
      </button>
    </aside>
  );
};
