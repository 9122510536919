import { useState, useEffect } from 'react';

import { EventDateTime, ProfilePhoto, SegmentedControl } from '..';
import { useEvents, useSitters, useTokenAuth } from '../../hooks';

import postJSON from '../../utils/postJSON';

import {
  ACTIONS,
  ARE_YOU_SURE,
  CHANGE_URL,
  CLOSE,
  DELETE,
  REMOVE_URL
} from '../../constants';

export default ({
  isPm = false,
  booking = {},
  setIsPm,
  setBooking,
  shouldSave,
  setShouldSave,
  setIsSidebarStart,
  customers
}) => {
  const { email, token } = useTokenAuth();
  const { fetchEvents } = useEvents();
  const { sitters = [] } = useSitters();
  const [lists, setLists] = useState([]);
  const [selectedSitter, setSelectedSitter] = useState();
  const [selectedList, setSelectedList] = useState();

  useEffect(() => {
    if (booking?.customerId && customers?.length) {
      const customer = (
        customers.find(({ id }) => id === booking.customerId)
      );

      if (customer?.lists) {
        setLists(customer.lists);
      }
    }
  }, [booking, customers]);

  useEffect(() => {
    if (!shouldSave && booking?.sitterId && sitters?.length) {
      const sitter = (
        sitters.find(({ id }) => id === booking.sitterId)
      );

      setSelectedSitter(sitter?.id);
      setSelectedList(booking?.listName);
    }
  }, [booking, sitters, selectedSitter]);

  const onClickDelete = async () => {
    if (!window.confirm(ARE_YOU_SURE)) return;

    const result = await postJSON(REMOVE_URL, {
      id: booking.id,
      token,
      username: email
    });

    if (result?.success) {
      fetchEvents();
    }

    setShouldSave(false);
    setBooking({});
    setSelectedSitter(null);
    setSelectedList(null);
  };

  const onClickViewProfile = () => {
    const slug = booking.catName
      ? booking.catName
          .trim()
          .replace(/,/g, '')
          .replace(/ /g, '-')
          .replace(/&/g, 'and')
          .toLowerCase()
      : '404';

    window.location.href = `/profile/${slug}`;
  };

  const onClickClose = async () => {
    if (shouldSave) {
      const result = await postJSON(CHANGE_URL, {
        bookingId: booking.id,
        start: booking.start,
        end: booking.end,
        sitterId: selectedSitter,
        listName: selectedList,
        token,
        username: email
      });

      if (result?.success) {
        fetchEvents();
      }

      setShouldSave(false);
    }

    setBooking({});
    setSelectedSitter(null);
    setSelectedList(null);
  };

  const onChangeLists = ({ target: { value }}) => {
    if (lists) {
      setShouldSave(true);
      setSelectedList(value);
    }
  };

  const onChangeSitters = ({ target: { value }}) => {
    if (sitters) {
      setShouldSave(true);
      setSelectedSitter(value);
    }
  };

  return (
    <div className={booking.id ? 'sidebar-open' : 'sidebar'}>
      <div onClick={onClickViewProfile} style={{ cursor: 'pointer' }}>
        <ProfilePhoto />
        <h3 style={{ fontSize: '1.2em' }}>{booking.title}</h3>
      </div>
      {booking.id && (
        <>
          <SegmentedControl
            startComponent={
              <EventDateTime
                startOrEnd="start"
                booking={booking}
                setBooking={setBooking}
                setShouldSave={setShouldSave}
                isPm={isPm}
                setIsPm={setIsPm}
              />
            }
            endComponent={
              <EventDateTime
                startOrEnd="end"
                booking={booking}
                setBooking={setBooking}
                setShouldSave={setShouldSave}
                isPm={isPm}
                setIsPm={setIsPm}
              />
            }
            booking={booking}
            setIsPm={setIsPm}
            setIsSidebarStart={setIsSidebarStart}
          />
          <p className="dashboard-heading" style={{ margin: 0 }}>Sitter</p>
          <select
            name="sitters"
            id="sitters"
            style={{
              display: 'block',
              background: 'transparent',
              border: '2px solid #9418ff',
              borderRadius: '.4rem',
              color: '#9418ff',
              width: '100%',
              margin: '1rem auto',
              padding: '.5rem',
              fontSize: '1em'
            }}
            onChange={onChangeSitters}
            value={selectedSitter}
          >
            {sitters.map(sitter => (
              <option
                key={sitter.id}
                defaultValue={selectedSitter === sitter.id}
                value={sitter.id}
              >
                {sitter.name}
              </option>
            ))}
          </select>
          <p className="dashboard-heading" style={{ margin: 0 }}>Tasks</p>
          <select
            name="lists"
            id="lists"
            style={{
              display: 'block',
              background: 'transparent',
              border: '2px solid #9418ff',
              borderRadius: '.4rem',
              color: '#9418ff',
              width: '100%',
              margin: '1rem auto',
              padding: '.5rem',
              fontSize: '1em'
            }}
            onChange={onChangeLists}
            value={selectedList}
          >
            {lists.map(list => (
              <option
                key={list.name}
                defaultValue={list.name === selectedList}
                value={list.name}
              >
                {list.name}
              </option>
            ))}
          </select>
          <p className="dashboard-heading">{ACTIONS}</p>
          <button
            onClick={onClickDelete}
            style={{
              background: 'transparent',
              border: '2px solid #ff1859',
              color: '#ff1859',
              width: '100%',
              padding: '.75rem 0',
              margin: '1rem 0 1.5rem'
            }}
          >
            {DELETE}
          </button>
        </>
      )}
      <button
        onClick={onClickClose}
        style={{
          width: '100%',
          padding: '.75rem 0',
          border: '2px solid #9418ff',
          margin: '1rem 0',
          justifySelf: 'flex-end'
        }}
      >
        {shouldSave ? 'Save & ' : ''}
        {CLOSE}
      </button>
    </div>
  );
};
