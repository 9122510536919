import React, { useEffect, useState } from 'react';
import { Calendar as BigCalendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { CreateSidebar, EditSidebar, SidebarNav } from '../../components';
import { useCustomers, useEvents, useTokenAuth } from '../../hooks';
import dayjs from 'dayjs';

import { CALENDAR, DAY, EVENT, LANGUAGE, MONTH, WEEK } from '../../constants';

import '../../dashboard.css';

const localizer = dayjsLocalizer(dayjs);

export default () => {
  const { token } = useTokenAuth();

  const { events, fetchEvents, isLoading: isEventsLoading } = useEvents();

  const { customers, isLoading: isCustomersLoading } = useCustomers();

  const [isLoading, setIsLoading] = useState(
    isEventsLoading || isCustomersLoading
  );
  const [selectedEvent, setSelectedEvent] = useState({});
  const [createdEvent, setCreatedEvent] = useState({});
  const [shouldSave, setShouldSave] = useState(false);
  const [isSidebarStart, setIsSidebarStart] = useState(true);
  const [isSidebarNavOpen, setIsSidebarNavOpen] = useState(false);
  const [isPm, setIsPm] = useState(false);

  const today = new Date();

  useEffect(() => {
    if (!token) {
      window.location.href = '/login';

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(isEventsLoading || isCustomersLoading);
  }, [isEventsLoading, isCustomersLoading]);

  useEffect(() => {
    const hours =
      selectedEvent[isSidebarStart ? 'start' : 'end']?.getHours?.() << 0;

    setIsPm(hours >= 12);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent]);

  const onSelectEvent = event => {
    setSelectedEvent(selectedEvent.title !== event.title && event);
  };

  const onClickCalendar = () => {
    if (selectedEvent?.id || createdEvent?.id) {
      setSelectedEvent({});
      setShouldSave(false);
      fetchEvents();
    }
  };

  const EventWrapper = ({ children, event: { start } }) => (
    <div
      style={{
        ...children.style,

        margin: '.3rem',
        borderRadius: '5px',
        backgroundColor:
          new Date(start.toDateString()) < new Date(today.toDateString())
            ? '#be9fff'
            : '#9418ff'
      }}
    >
      {children}
    </div>
  );

  const onClickMenu = () => {
    setIsSidebarNavOpen(!isSidebarNavOpen);
  };

  const onClickCreateEvent = () => {
    if (!customers?.length) return;

    const { id, catName, ownerName } = customers[0];

    setCreatedEvent({
      id: 'pending',
      title: `${catName} (${ownerName})`,
      start: today,
      end: today,
      desc: '',
      catName: catName,
      ownerName: ownerName,
      customerId: id
    });
  };

  return isLoading ? (
    <div />
  ) : (
    <>
      <section className="sidebar-section">
        <SidebarNav isOpen={isSidebarNavOpen} setIsOpen={setIsSidebarNavOpen} />
        <div className="calendar-wrapper" onClick={onClickCalendar}>
          <h2>
            <i onClick={onClickMenu}>=</i>
            {CALENDAR}
          </h2>
          <div>
            <button
              className="primary-action-button"
              onClick={onClickCreateEvent}
            >
              + {EVENT}
            </button>
          </div>
          <BigCalendar
            culture={LANGUAGE}
            defaultDate={today}
            events={events}
            localizer={localizer}
            onSelectEvent={onSelectEvent}
            components={{
              eventWrapper: EventWrapper
            }}
            views={[MONTH, WEEK, DAY]}
          />
        </div>
      </section>
      <CreateSidebar
        isPm={isPm}
        booking={createdEvent}
        shouldSave={shouldSave}
        setIsPm={setIsPm}
        setBooking={setCreatedEvent}
        setShouldSave={setShouldSave}
        setIsSidebarStart={setIsSidebarStart}
        customers={customers}
      />
      <EditSidebar
        isPm={isPm}
        booking={selectedEvent}
        shouldSave={shouldSave}
        setIsPm={setIsPm}
        setBooking={setSelectedEvent}
        setShouldSave={setShouldSave}
        fetchEvents={fetchEvents}
        setIsSidebarStart={setIsSidebarStart}
        customers={customers}
      />
    </>
  );
};
